<style type="text/css">
    @import "~@/assets/css/common.css";
    @import "~@/assets/css/index.css";
    @import "~@/assets/css/list.css";
</style>
<script>
    import Header from "./index-header.vue";
    import Footer from "./index-footer.vue";
    import Navbar from "./navbar.vue";
    import commonPagination from "../common/commonPagination";
    import {getLibraryList, getgrade} from "@/api/grade";

    /**
     * Dashboard component
     */
    export default {
        components: {
            Header,
            Navbar,
            Footer,
        },
        data() {
            return {
                time: "", //考试时间
                timeList: [],
                recordList: [],
                loginInfo: {}
            };
        },
        methods: {
            /**
             * 跳转登录
             */
            goToLogin() {
                window.open("/login");
            },
            getGrade(cjkid) {
                let _this = this;
                _this.$router.push({
                  path: "/index/scoreSearch",
                  query: {
                    sid: cjkid,
                  },
                });

                // getgrade({
                //     cjkid: cjkid,
                // }).then((res) => {
                //     if (res.status) {
                //         if (res.data) {
                //             _this.$router.push({
                //                 path: "/index/scoreSearch",
                //                 query: {
                //                     sid: cjkid,
                //                 },
                //             });
                //         } else {
                //             if (res.message == "操作成功") {
                //                 this.$message({
                //                     message: "该成绩库未查询到成绩",
                //                     type: "warning",
                //                 });
                //             } else {
                //                 this.$message({
                //                     message: res.message,
                //                     type: "warning",
                //                 });
                //             }
                //         }
                //     }
                // });
            },
            searchScore(index) {
                let sid = this.recordList[index].sid;
                this.getGrade(sid);
            },
            change() {
                this.getLibrary(this.time);
            },

            getLibrary(ksnf) {
                this.recordList = [];
                getLibraryList({ksnf}).then((res) => {
                    if (res.status) {
                        let time = new Date().getTime();
                        if (res.data) {
                            res.data.forEach((item) => {
                                if (item.cscx && this.loginInfo.from == 'admin') {
                                    this.recordList.push(item);
                                } else {
                                    if (
                                        item.kfrq &&
                                        time > new Date(item.kfrq.replace(/-/g, "/")).getTime()
                                    ) {
                                        if (item.jsrq) {
                                            if (
                                                time < new Date(item.jsrq.replace(/-/g, "/")).getTime()
                                            ) {
                                                this.recordList.push(item);
                                            }
                                        } else {
                                            this.recordList.push(item);
                                        }
                                    }
                                }
                            });
                        }
                        this.recordList = this.recordList.filter((v) => {
                            return v.sfyxcx == 1;
                        });
                    }
                });
            },
        },
        mounted() {
            let year = new Date().getFullYear();
            this.timeList = [year, year - 1, year - 2, year - 3, year - 4];
            this.time = year;
            this.getLibrary(year);
        },
        created() {
            // this.loginInfo = JSON.parse(localStorage.getItem('userInfo'))

        },
    };
</script>

<template>
    <div>
        <Header></Header>
        <div class="main">
            <div class="wp">
                <Navbar></Navbar>
                <div class="white-bg">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" style="min-height: 600px">
                                <div class="card-body">
                                    <h4 class="card-title pb-3 border-dash flexList">
                                        <el-select
                                                v-model="time"
                                                placeholder="请选择年份"
                                                style="min-width: 6%"
                                                size="small"
                                                class="m-2 w-10"
                                                @change="change"
                                        >
                                            <el-option
                                                    v-for="item in timeList"
                                                    :key="item"
                                                    :label="item+'年'"
                                                    :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                        <!-- <button type="button" class="btn btn-info h35 flexList w-xs">
                                          <i class="iconfont mr-2 icon-mb-search"></i>查询
                                        </button> -->
                                    </h4>
                                    <div class="table-responsive border mt-3">
                                        <table
                                                class="table table-has-bg light-table table-hover table-bordered"
                                        >
                                            <thead class="thead-light">
                                            <tr>
                                                <th style="width: 4%">序号</th>
                                                <!-- <th style="width: 15%">成绩单名称</th> -->
                                                <th>考试名称</th>
                                                <th style="width: 8%">年份</th>
                                                <th style="width: 8%">操作</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(item, index) in recordList" :key="index">
                                                <td scope="row">{{ index + 1 }}</td>
                                                <!-- <td>{{ item.cjdmc }}</td> -->
                                                <td>
                                                    {{ item.ksmc }}
                                                </td>
                                                <td>{{ item.ksnf }}</td>
                                                <td class="table-btn">
                                                    <button
                                                            type="button"
                                                            class="btn btn-info h30"
                                                            @click="searchScore(index)"
                                                    >
                                                        <i
                                                                class="iconfont icon-fasfa-sign-in-alt align-middle mr-1"
                                                        ></i
                                                        >进入查询
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center"><button
                                            type="button"
                                            class="btn btn-info h30"
                                            @click="goToLogin"
                                    >
                                        点击查询更多成绩
                                    </button></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
      <Footer></Footer>

    </div>

</template>
